import React from 'react'
import DatetimeContainer from '../containers/Datetime'
import SettingsContainer from '../containers/Settings'
import styles from './Greeting.module.scss'

const Greeting = () => {
  const { hours } = DatetimeContainer.useContext()
  const {
    settings: { name }
  } = SettingsContainer.useContext()

  const greeting =
    hours >= 3 && hours < 12
      ? 'Good morning'
      : hours >= 12 && hours < 17
      ? 'Good afternoon'
      : 'Good evening'
  return (
    <div className={styles.greeting}>
      {greeting}
      {name !== '' ? `, ${name}` : null}
    </div>
  )
}

export default Greeting
