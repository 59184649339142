import { useState } from 'react'
import loadLocalData from '../modules/loadLocalData'
import { createContainer } from '../modules/container'

const useLinks = () => {
  const [links, _updateLinks] = useState(
    loadLocalData('links', [], x => JSON.parse(x))
  )
  const updateLinks = links => {
    if (Array.isArray(links)) {
      localStorage.setItem('links', JSON.stringify(links))
      _updateLinks(links)
    }
  }

  return {
    links,
    updateLinks,
    addLink(title, url) {
      updateLinks([...links, [title, url]])
    },
    deleteLink(title, url) {
      updateLinks(links.filter(link => link[0] !== title && link[1] !== url))
    }
  }
}

export default createContainer(useLinks)
