import React, { Suspense } from 'react'
import ModalContainer from '../containers/Modal'
import styles from './App.module.scss'

import Background from './Background'
import SpeedDialer from './SpeedDialer'
import Clock from './Clock'
import Greeting from './Greeting'

import Button from './Button'
import LinksIcon from './LinksIcon'
import SettingsIcon from './SettingsIcon'

import Modal from './Modal'
const Links = React.lazy(() => import('./Links'))
const Settings = React.lazy(() => import('./Settings'))
const About = React.lazy(() => import('./About'))
const Import = React.lazy(() => import('./Import'))
const Export = React.lazy(() => import('./Export'))
const Reset = React.lazy(() => import('./Reset'))

const App = () => {
  const { modalState, updateModalState } = ModalContainer.useContext()

  const handleToggleLinks = evt => {
    if (modalState === 'links') {
      updateModalState(false)
    } else {
      evt.stopPropagation()
      updateModalState('links')
    }
  }

  const handleToggleSettings = evt => {
    if (modalState === 'settings') {
      updateModalState(false)
    } else {
      evt.stopPropagation()
      updateModalState('settings')
    }
  }

  if (location.search !== '') {
    updateModalState('import')
  }

  return (
    <Background>
      <SpeedDialer />
      <div className={styles.modals}>
        <Modal className={styles.modal} showOnState="about">
          <Suspense fallback={<div />}>
            <About />
          </Suspense>
        </Modal>
        <Modal className={styles.modal} showOnState="import">
          <Suspense fallback={<div />}>
            <Import />
          </Suspense>
        </Modal>
        <Modal className={styles.modal} showOnState="export">
          <Suspense fallback={<div />}>
            <Export />
          </Suspense>
        </Modal>
        <Modal className={styles.modal} showOnState="reset">
          <Suspense fallback={<div />}>
            <Reset />
          </Suspense>
        </Modal>
      </div>
      <div className={styles.bottomLeft}>
        <Clock />
        <Greeting />
      </div>
      <div className={styles.topRight}>
        <Button icon onClick={handleToggleLinks}>
          <LinksIcon />
        </Button>
        <Modal
          className={styles.links}
          showOnState={['links', 'addLink', 'deleteLink']}
        >
          <Suspense fallback={<div />}>
            <Links />
          </Suspense>
        </Modal>
      </div>
      <div className={styles.bottomRight}>
        <Modal className={styles.settings} showOnState="settings">
          <Suspense fallback={<div />}>
            <Settings />
          </Suspense>
        </Modal>
        <Button icon onClick={handleToggleSettings}>
          <SettingsIcon />
        </Button>
      </div>
    </Background>
  )
}
export default App
