import { useRef, useEffect } from 'react'

const useOnBlur = onBlur => {
  const ref = useRef()
  const handleClick = e => {
    if (
      document.body.contains(e.target) &&
      ref.current &&
      !ref.current.contains(e.target)
    ) {
      onBlur()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return [ref]
}

export default useOnBlur
