import { useState, useEffect } from 'react'
import { createContainer } from '../modules/container'

const getDateProperties = (date = new Date()) => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
  hours: date.getHours(),
  minutes: date.getMinutes()
})

const calculateCacheKey = datetime =>
  [
    datetime.year,
    datetime.month,
    datetime.day,
    datetime.hours,
    datetime.minutes
  ].join('')

const useDatetime = () => {
  const [datetime, updateDatetime] = useState(getDateProperties())
  useEffect(() => {
    const timer = setInterval(() => {
      const newDatetime = getDateProperties()
      if (calculateCacheKey(datetime) !== calculateCacheKey(newDatetime)) {
        updateDatetime(newDatetime)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [datetime, updateDatetime])

  return datetime
}

export default createContainer(useDatetime)
