import { useState } from 'react'
import { createContainer } from '../modules/container'

const useRefresh = () => {
  const [refreshing, _updateRefreshing] = useState(false)
  return {
    refreshing,
    updateRefreshing(value) {
      _updateRefreshing(!!value)
    }
  }
}

export default createContainer(useRefresh)
