import { useState } from 'react'
import loadLocalData from '../modules/loadLocalData'
import { createContainer } from '../modules/container'

const useSettings = () => {
  const [settings, _updateSettings] = useState({
    name: loadLocalData('settings.name', 'Stranger'),
    mode: loadLocalData('settings.mode', 'likes'),
    username: loadLocalData('settings.username', 'scottmartin'),
    dayCollection: loadLocalData('settings.dayCollection', '4773560'),
    nightCollection: loadLocalData('settings.nightCollection', '4822369'),
    color: loadLocalData('settings.color', '#333333'),
    url: loadLocalData('settings.url', '')
  })

  return {
    settings,
    updateSettings(changes) {
      const filteredChanges = Object.keys(changes)
        // filter out keys that aren't already in the settings object
        .filter(key => settings.hasOwnProperty(key))
        .reduce((filtered, key) => {
          localStorage.setItem(`settings.${key}`, changes[key])
          return { ...filtered, [key]: changes[key] }
        }, {})

      _updateSettings({ ...settings, ...filteredChanges })
    }
  }
}

export default createContainer(useSettings)
