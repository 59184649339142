import React from 'react'
import styles from './Button.module.scss'

const Button = ({ icon, link, submit, className, onClick, children }) => {
  let classes = [styles.button]
  if (icon) {
    classes.push(styles.icon)
  }
  if (link) {
    classes.push(styles.link)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classes.join(' ')}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
