import React from 'react'
import styles from './Favicon.module.scss'

export const getFaviconFromURL = url => {
  const { hostname } = new URL(url)
  return `https://proxy.duckduckgo.com/ip3/${encodeURIComponent(hostname)}.ico`
}

const Favicon = ({ url }) => {
  return <img className={styles.icon} src={getFaviconFromURL(url)} alt="" />
}

export default Favicon
