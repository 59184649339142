import React from 'react'
import DatetimeContainer from '../containers/Datetime'
import styles from './Clock.module.scss'

const Clock = () => {
  const { hours, minutes } = DatetimeContainer.useContext()
  const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
  const displayMinutes = minutes < 10 ? '0' + minutes : minutes

  return (
    <div className={styles.clock}>
      {displayHours}
      <span>:</span>
      {displayMinutes}
    </div>
  )
}

export default Clock
