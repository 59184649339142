import React, { createContext, useContext } from 'react'

export const createContainer = useHook => {
  const Context = createContext()
  return {
    Provider({ children }) {
      const value = useHook()
      return <Context.Provider value={value}>{children}</Context.Provider>
    },
    useContext() {
      let value = useContext(Context)
      if (value === undefined) {
        throw new Error('A context was used without a value from its Provider.')
      }
      return value
    }
  }
}

export const Provider = ({ containers, children }) => {
  const Provider = containers.reduce(
    (PreviousProvider, { Provider: ContainerProvider }) => {
      if (PreviousProvider) {
        return ({ children }) => (
          <PreviousProvider>
            <ContainerProvider>{children}</ContainerProvider>
          </PreviousProvider>
        )
      } else {
        return ({ children }) => (
          <ContainerProvider>{children}</ContainerProvider>
        )
      }
    },
    false
  )

  return <Provider>{children}</Provider>
}
