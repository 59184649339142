import React, { useState, useEffect } from 'react'
import loadLocalData from '../modules/loadLocalData'
import DatetimeContainer from '../containers/Datetime'
import SettingsContainer from '../containers/Settings'
import RefreshContainer from '../containers/Refresh'
import styles from './Background.module.scss'

const calculateCacheKey = datetime =>
  [
    datetime.year,
    datetime.month,
    datetime.day,
    Math.floor(datetime.hours / 6)
  ].join('')

const calculateCustomUnsplashURL = url => {
  if (url !== '') {
    const clientWidth = document.documentElement.clientWidth || 1920
    const clientHeight = document.documentElement.clientHeight || 1080

    const parts = url.split('?')
    const urlParams = new URLSearchParams(parts[1])
    urlParams.set('w', clientWidth)
    urlParams.set('h', clientHeight)
    return [parts[0], urlParams.toString()].join('?')
  }
  return ''
}

const Background = ({ children }) => {
  const [fetching, updateFetching] = useState(false)
  const [backgroundURL, updateBackgroundURL] = useState(
    loadLocalData('background.url', '')
  )
  const [cacheKey, setCacheKey] = useState(loadLocalData('background.key', ''))

  const datetime = DatetimeContainer.useContext()
  const { refreshing, updateRefreshing } = RefreshContainer.useContext()
  const { settings } = SettingsContainer.useContext()

  useEffect(() => {
    if (settings.mode === 'color' || settings.mode === 'url') {
      return false
    }

    if (!fetching) {
      if (
        !refreshing &&
        backgroundURL &&
        cacheKey === calculateCacheKey(datetime)
      ) {
        return
      }
      updateFetching(true)

      let sourceURL
      if (settings.mode === 'collections') {
        const collectionID =
          datetime.hours >= 6 && datetime.hours < 18
            ? settings.dayCollection
            : settings.nightCollection
        sourceURL = `https://source.unsplash.com/collection/${collectionID}`
      } else {
        sourceURL = `https://source.unsplash.com/user/${settings.username}/likes`
      }

      fetch(sourceURL, { method: 'head' })
        .then(res => {
          if (!res.ok) {
            updateRefreshing(false)
            updateFetching(false)
            return
          }

          if (res.url === backgroundURL && !refreshing) {
            updateRefreshing(true)
            return
          }

          const newCacheKey = calculateCacheKey(datetime)
          localStorage.setItem('background.key', newCacheKey)
          localStorage.setItem('background.url', res.url)
          setCacheKey(newCacheKey)
          updateBackgroundURL(res.url)
          updateRefreshing(false)
          updateFetching(false)
        })
        .catch(() => {
          updateRefreshing(false)
          updateFetching(false)
        })
    }
  }, [
    datetime.hours,
    fetching,
    updateFetching,
    backgroundURL,
    updateBackgroundURL,
    cacheKey,
    setCacheKey,
    refreshing,
    updateRefreshing,
    settings.mode,
    settings.username,
    settings.dayCollection,
    settings.nightCollection
  ])

  let url
  if (settings.mode === 'url' && settings.url) {
    url = settings.url
  } else {
    url = calculateCustomUnsplashURL(backgroundURL)
  }

  return (
    <div
      className={styles.background}
      style={{
        backgroundColor: settings.color,
        backgroundImage: settings.mode !== 'color' ? `url('${url}')` : null
      }}
    >
      {children}
    </div>
  )
}

export default Background
