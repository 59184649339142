import './styles/main.scss'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from './modules/container'

import DatetimeContainer from './containers/Datetime'
import ModalContainer from './containers/Modal'
import RefreshContainer from './containers/Refresh'
import LinksContainer from './containers/Links'
import SettingsContainer from './containers/Settings'

const containers = [
  DatetimeContainer,
  ModalContainer,
  RefreshContainer,
  LinksContainer,
  SettingsContainer
]

import App from './components/App'

render(
  <Provider containers={containers}>
    <App />
  </Provider>,
  document.querySelector('#root')
)
