import { useState } from 'react'
import loadLocalData from '../modules/loadLocalData'
import { createContainer } from '../modules/container'

const useModal = () => {
  const [modalState, _updateModalState] = useState(
    loadLocalData('modals.about', true, x => x === 'true') ? 'about' : false
  )

  return {
    modalState,
    updateModalState(state) {
      if (state === 'about') {
        localStorage.setItem('modals.about', true)
      } else {
        localStorage.setItem('modals.about', false)
      }
      _updateModalState(state)
    }
  }
}

export default createContainer(useModal)
