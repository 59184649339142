import React from 'react'
import useOnBlur from '../modules/useOnBlur'
import ModalContainer from '../containers/Modal'
import styles from './Modal.module.scss'

const Modal = ({ showOnState, className, children, closeOnBlur = true }) => {
  const { modalState, updateModalState } = ModalContainer.useContext()
  const show = Array.isArray(showOnState)
    ? showOnState.includes(modalState)
    : modalState === showOnState
  const [ref] = useOnBlur(() => {
    if (closeOnBlur) {
      updateModalState(false)
    }
  })

  const classes = [styles.modal]
  if (className) {
    classes.push(className)
  }

  if (show) {
    return (
      <div ref={ref} className={classes.join(' ')}>
        {children}
      </div>
    )
  } else {
    return null
  }
}

export default Modal
