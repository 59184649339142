import React, { useState, useRef, useEffect } from 'react'
import LinksContainer from '../containers/Links'
import styles from './SpeedDialer.module.scss'
import modalStyles from './Modal.module.scss'

import Favicon, { getFaviconFromURL } from './Favicon'

const SpeedDialer = () => {
  const { links } = LinksContainer.useContext()
  const [dialing, updateDialing] = useState(false)

  const handleDial = evt => {
    // Ignore modified keys
    if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) {
      return
    }
    // Ignore typing in inputs
    if (evt.target.tagName === 'INPUT') {
      return
    }
    // Ignore anything except 0-9
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(evt.key)) {
      return
    }
    const dialedLink = parseInt(evt.key === '0' ? '10' : evt.key, 10) - 1
    if (links[dialedLink]) {
      // Preload dialed favicon
      const image = new Image()
      image.src = getFaviconFromURL(links[dialedLink][1])

      updateDialing(links[dialedLink])
      setTimeout(() => {
        window.location.href = links[dialedLink][1]
      }, 200)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleDial)
    return () => {
      window.removeEventListener('keydown', handleDial)
    }
  }, [handleDial, links])

  const ref = useRef()
  useEffect(() => {
    if (ref.current) {
      ref.current.classList.add('show')
    }
  }, [dialing, ref])

  if (dialing) {
    return (
      <div ref={ref} className={`${modalStyles.modal} ${styles.dialing}`}>
        Navigating to <Favicon url={dialing[1]} /> {dialing[0]}...
      </div>
    )
  } else {
    return null
  }
}

export default SpeedDialer
